import React from 'react';
import './LetterPage.css'; // Archivo de estilos

const LetterPage = () => {
  const pdfURL = `${process.env.PUBLIC_URL}/letter.pdf`;

  const handleOpenPDF = () => {
    window.open(pdfURL, '_blank');
  };

  return (
    <div className="letter-container">
      <div className="letter-header">
        <h1>Querida Daisy ❤️</h1>
        <p className="intro-text">
          Te escribo esta carta de la mejor manera que sé hacerlo. 
          Espero que al leerla sientas todo lo que significas para mí.
        </p>
      </div>
      <div className="letter-message">
        <blockquote>
          Quiero dejar aquí el recuerdo de como me enamore de ti, 
          o como te gusta llamarlo, <span className="highlight">“nuestro bello click”</span>. 
        </blockquote>
      </div>
      <div className="letter-actions">
        <button className="open-pdf-btn" onClick={handleOpenPDF}>
          Ver Carta Completa <span role="img" aria-label="document">📄</span>
        </button>
      </div>
      <div className="letter-footer">
        <p>Te amo como no tienes idea. 💌</p>
      </div>
    </div>
  );
};

export default LetterPage;
